<template>
  <checkouts :checkoutName="$route.params.lp" />
</template>

<script>
import Checkouts from '@/components/Checkouts.vue'
export default {
  name: 'CheckoutLP',
  components: { Checkouts },
}
</script>

<style></style>
